import React from "react"
import { Link } from "gatsby"
import { Helmet } from "react-helmet"
import Layout from "../components/Layout"

/* Basic content page structure */

class Terms extends React.Component {
  componentDidMount() {
    // Inactivity()
    document.body.classList.remove('-nav-active')
  }

  render() {
    return (
      <Layout>
        <Helmet>
          <title>Meet MJN - Terms of Use</title>
        </Helmet>
        <main role="main" className="content-main">

          <section className="hero-area -no-content">

          </section>

          <section className="rich-text -bg-white">
            <nav className="breadcrumb" role="navigation">
              <Link to="/home" title="Home">
                <i className="fas fa-arrow-left"></i>
                Home
              </Link>
            </nav>

            <p className="uppercase"><strong>PLEASE READ THESE TERMS AND CONDITIONS CAREFULLY BEFORE USING THE WEBSITE</strong></p>

            <h1>WEBSITE ACCESS TERMS AND CONDITIONS</h1>

            <p><strong>These terms and conditions (the “Terms”) are the terms on which this website (the “Website”) is made available to you (“you”/”your”). By accessing this Website you agree to be bound by these Terms. If you do not agree to these Terms, please do not use the Website.</strong></p>

            <ol>
              <li><span className="uppercase">INFORMATION ABOUT US</span>
                <ol>
                  <li>We are Reckitt Benckiser Group plc (“<strong>we</strong>”/ “<strong>us</strong>”/ “<strong>our</strong>”), a company registered in England under registration number 06270876, and our registered address is 103-105 Bath Road, Slough, SL1 3UH, United Kingdom. We are a Reckitt Bensicker company.</li>
                </ol>
              </li>
              <li><span className="uppercase">Questions or Complaints</span>
                <ol>
                  <li>If you have any questions, complaints or comments on this Website then you may contact us at Corporate Communications on +44 (0) 1753 217 800. Our corporate Website at rb.com contains a list of our local companies and their contact details.</li>
                </ol>
              </li>
              <li><span className="uppercase">Our COPYRIGHT and other INTELLECTUAL Property Rights</span>
                <ol>
                  <li>Your use of the Website and its contents grants no rights to you in relation to any of the intellectual property rights or associated rights, including copyrights, trade marks, patents, design rights, trade names, database rights, and neighbouring rights, as well as rights to know-how (“<strong>Intellectual Property Rights</strong>”), related to the Website. All text, user interfaces, visual interfaces, graphics, illustrations, photographs, trade marks, logos, computer code and other related material (together, “<strong>Content</strong>”), including but not limited to the design, arrangement, structure, selection, coordination, expression and “look and feel” of the Content, contained on the Website are owned or controlled by us or licensed to us by our third party licensors. All such rights are reserved.</li>
                  <li>Nothing in the Terms constitutes the transfer of any Intellectual Property Rights from us to you or any third party.</li>
                  <li>You may not copy, reproduce, republish, download, post, broadcast, record, transmit, commercially exploit, edit, communicate to the public or distribute in any way the Content, services, web pages or materials on the Website or the computer codes of elements comprising the Website other than for your own personal use. You also may not use any automatic or manual device, program, algorithm or methodology, or any similar process on any portion of the Website or Content. Subject to the above, you may download insubstantial excerpts of this content to your hard disk for the purpose of viewing it provided that no more than one copy of any information is made.</li>
                  <li>Any use other than that permitted under this clause 3 may only be undertaken with our prior written authorisation.</li>
                  <li>We do not purport to use any name, logo or mark in any territory in which we are not so entitled, and will not supply or offer to supply products and/or services bearing any such name, logo or mark into any such territory.</li>
                </ol>
              </li>
              
              <li><span className="uppercase">oTHER rECKITT bENCKISER WEBSITES AND BRANDS</span>
                <ol>
                  <li>Any products or services which we make available to you on or via other Reckitt Benckiser websites or websites from our brands (referred to below as “Brand Websites”) are subject to additional terms and conditions which will be notified to you when you access the relevant Brand Websites.</li>
                  <li>This Website and the Brand Websites which are designed to provide information about our healthcare products or licensed medicines are not intended to provide medical advice or instructions as to use but only as general information which should not be relied upon by any individual or for any specific purpose. Always consult your doctor or pharmacist for advice on treatment of individual circumstances and needs.</li>
                </ol>
              </li>

              <li><span className="uppercase">Purchases</span>
                <ol>
                  <li>Nothing in the Website or any Brand Website constitutes an offer to buy or sell products and/or services in any jurisdiction since the Website is provided for information purposes only. All details, descriptions and prices of, and other information relating to, products and/or services appearing on the Website or any Brand Website are of a general nature only. We do not guarantee that any product and/or service appearing on the Website or any Brand Website is or will be available at the location and time you wish to purchase any particular product and/or service.</li>
                  <li>Our obligations, if any, with regard to products and services are governed solely by the agreements pursuant to which they are provided, and nothing on this Website should be construed to alter such agreements.</li>
                </ol>
              </li>

              <li><span className="uppercase">LINKS FROM OUR SITE</span>
                <ol>
                  <li>We may provide links to other websites from time to time (via advertising or otherwise). These links are provided for your ease of reference and convenience only. We do not control such third party websites and are not responsible for their contents. Our inclusion of links does not imply any endorsement of the material contained in such websites or any association with their operators. You acknowledge that we will not be party to any transaction or contract with a third party that you may enter into and we shall not be liable to you in respect of any loss or damage which you may suffer by using those websites. You agree that you will not involve us in any dispute between you and the third party.</li>
                </ol>
              </li>

              <li><span className="uppercase">YOUR USE OF THE WEBSITE</span>
                <ol>
                  <li>You agree that in using the Website you will not:
                    <ol>
                      <li>use the Website in any way that may lead to the encouragement, procurement or carrying out of any activity which is criminal, fraudulent, unlawful or prohibited by these Terms;</li>
                      <li>use the Website for any purpose other than your personal use;</li>
                      <li>advertise or promote third party or your own products or services including by way of the distribution of ‘spam’ email;</li>
                      <li>transfer files that contain viruses, trojans or engage in any other activity harmful to the Website;</li>
                      <li>link to the Website from a third party site without our prior written authorisation;</li>
                      <li>access or attempt to gain unauthorised access to any user accounts linked or associated with the Website or to penetrate or attempt to penetrate the Website security measures; or</li>
                      <li>interfere with any other person’s access to, use or enjoyment of, the Website.</li>
                    </ol>
                  </li>
                  <li>Each and every access and use of the Website is made at your own risk and responsibility. You should use your own virus protection software.</li>
                  <li>You are also responsible for ensuring that all persons who access the Website through your internet connection are aware of these Terms and any other applicable terms and conditions listed in these Terms, and that they comply with them.</li>
                  <li>We reserve the right to suspend, restrict or terminate your access to this Website (or any part of it) at any time without notice at our discretion if we believe you have breached any of the restrictions in these Terms.</li>
                </ol>
              </li>
              
              <li><span className="uppercase">User uploaded content</span>
                <ol>
                  <li>Content uploaded by you:
                    <ol>
                      <li>By submitting information, text, photos, graphics or other content to us via the Website, you grant us a right to use such materials at our own discretion including, without limitation, to edit, copy, reproduce, disclose, post and remove such materials from the Website.</li>
                      <li>You warrant in respect of any such contribution:
                        <ul>
                          <li>(a) that it complies with any applicable law and these Terms;</li>
                          <li>(b) that is true, accurate and up to date in all respects and at all times (note that you can request that we update or correct your personal details at any time by contacting us via the [above/below] contact details);</li>
                          <li>(c) that all necessary licences and/or approvals have been obtained and you have the right to make such contribution; and</li>
                          <li>(d) that unless stated otherwise, is not confidential.</li>
                        </ul>
                        and you will be liable to us and compensate us for any breach of that warranty. This means you will be responsible for any loss or damage we suffer as a result of your breach of warranty.
                      </li>
                      <li>We have the right to remove any posting you make on our Website.</li>
                      <li>You are solely responsible for securing and backing up your content.</li>
                    </ol>
                  </li>
                  <li>Content uploaded by other users:
                    <ol>
                      <li>This Website may include information and materials uploaded by other users of the Website, including to bulletin boards and chat rooms (if any). This information and these materials have not been verified or approved by us. The views expressed by other users on our Website do not represent our views or values.</li>
                    </ol>
                  </li>
                </ol>
              </li>

              <li><span className="uppercase">YOUR LEGAL OBLIGATIONS</span>
                <ol>
                  <li>You confirm that:
                    <ol>
                      <li>you are over the age of majority in your jurisdiction or you have the consent of your parent or legal guardian; and</li>
                      <li>you will comply with the restrictions on your use of the Website as set out in these Terms.</li>
                    </ol>
                  </li>
                  <li>You agree to compensate us from any claim or damages (including any legal fees in relation to such claim or damages) made by a third party in respect of any matter in relation to or arising from your use of the Website including any breach or suspected breach of these Terms or your violation of any law or the rights of a third party.</li>
                </ol>
              </li>

              <li><span className="uppercase">RELIANCE ON INFORMATION POSTED</span>
                <ol>
                  <li>The content on our Website is provided for general information only. It is not intended to amount to advice on which you should rely. You must obtain professional or specialist advice before taking, or refraining from, any action on the basis of the content on our Website.</li>
                  <li>Whilst we aim to ensure that the Website and its content, are correct at the time when such content is uploaded to the Website, it is subject to change and, to the extent permitted by applicable laws and regulations, we make no representations, warranties or guarantees (whether express or implied), that the information on the Website or its content is accurate, complete or up to date.</li>
                  <li>All content and services on the Website are provided on an ‘as is’ and ‘as available’ basis and, to the extent permitted by applicable laws and regulations, made without any representations, warranties or guarantees of any kind.</li>
                  <li>We are under no obligation to update any information contained on the Website.</li>
                </ol>
              </li>

              <li><span className="uppercase">We may suspend or withdraw our Website</span>
                <ol>
                  <li>Whilst we aim to ensure that the Website, its content, any features offered through the Website and any information contained on it, are error-free, uninterrupted and free from bugs and viruses, due to the nature of the internet, we cannot guarantee this.</li>
                  <li>We do not guarantee nor warrant that the Website, or any content on it, will always be available or be uninterrupted and in a fully operating condition. We may suspend or withdraw or restrict the availability of all or any part of our Website for business and operational reasons.</li>
                  <li>Access to the Website may be suspended temporarily and without notice in the case of system failure, maintenance or repair or for reasons reasonably beyond our control.</li>
                </ol>
              </li>

              <li><span className="uppercase">Our LIABILITY if you are a Consumer</span>
                <ol>
                  <li>Please note that we only provide our Website for domestic and private use. You agree not to use our Website for any commercial or business purposes, and we have no liability to you for any loss of profit, loss of business, business interruption, or loss of business opportunity.</li>
                  <li>If defective digital content that we have supplied damages a device or digital content belonging to you and this is caused by our failure to use reasonable care and skill, we will either repair the damage or pay you compensation. However, we will not be liable for damage that you could have avoided by following our advice to apply an update offered to you free of charge or for damage that was caused by you failing to correctly follow installation instructions or to have in place the minimum system requirements advised by us.</li>
                  <li>We shall have no liability to you for any breach of these Terms caused by any event or circumstance beyond our reasonable control including, but not limited to, strikes, lock-outs or other industrial disputes; breakdown of systems or network access; or flood, fire, explosion or accident.</li>
                  <li>Notwithstanding anything to the contrary, we do not exclude or limit in any way our liability to you where it would be unlawful to do so (for example liability for death or personal injury caused by our negligence or for fraud or fraudulent misrepresentation). Nothing in these Terms shall restrict your statutory rights.</li>
                </ol>
              </li>

              <li><span className="uppercase">Our LIABILITY if you are a business</span>
                <ol>
                  <li>Subject to 12.4 and insofar as permitted by applicable law, we exclude all liability (including liability of our officers, directors, managers, members, shareholders, employees, agents or advisors), whether arising in contract, tort, breach of statutory duty or otherwise, arising out of or in connection with access to or use of the Website, even if foreseeable, including but not limited to:
                    <ol>
                      <li>losses arising from inaccuracies in any information or material within or relating to the Website;</li>
                      <li>losses not caused by any breach on our part;</li>
                      <li>losses arising from your use of, or reliance on, the Website;</li>
                      <li>losses arising from the unavailability of the Website for whatsoever reason;</li>
                      <li>losses arising from any representation or statement made on the Website;</li>
                      <li>losses resulting from technical faults with the Website or technologically harmful material;</li>
                      <li>any business loss (including loss of profits, business, revenue, contracts, anticipated savings, data, goodwill, reputation, wasted expenditure, business interruption or loss of business opportunity); and</li>
                      <li>any indirect or consequential losses or losses that were not foreseeable to both you and us when you commencing accessing and using the Website.</li>
                    </ol>
                  </li>
                  <li>To the extent possible under applicable law, we exclude all implied conditions, warranties, representations or other terms that may apply to our Website or any content on it.</li>
                  <li>You will indemnify and compensate us and our officers, directors, managers, members, employees, agents and advisors for any losses, costs, liabilities and expenses (including reasonable attorneys’ fees) relating to or arising out of:
                    <ol>
                      <li>your use of the Website or Brand Websites (and related use of products and services);</li>
                      <li>your breach of these Terms;</li>
                      <li>your violation of any law or the rights of any third party; and/or</li>
                      <li>our use of your information.</li>
                    </ol>
                  </li>
                  <li>Notwithstanding anything to the contrary, we do not exclude or limit in any way our liability to you where it would be unlawful to do so (for example liability for death or personal injury caused by our negligence or for fraud or fraudulent misrepresentation).</li>
                </ol>
              </li>

              <li><span className="uppercase">How we may use your personal information</span>
                <ol>
                  <li>We are committed to protecting your privacy and only use any personal data that we collect from you, or that you provide to us, in accordance with applicable data protection laws and regulations.</li>
                  <li>Please read our Privacy Notice available at <Link to="/privacy-notice" title="Privacy Notice">privacy-notice.html</Link> and our Cookies Policy available at <Link to="/cookies-policy" title="Cookies Policy">cookies-policy.html</Link> for more information on how we use personal data and related matters.</li>
                </ol>
              </li>

              <li><span className="uppercase">CHANGES TO THESE TERMS AND CONDITIONS</span>
                <ol>
                  <li>We are constantly looking for ways to improve this Website. We therefore reserve the right to amend these Terms at any time. All such changes will take effect once they have been posted on the Website. Please ensure that you revisit and review these Terms regularly as you will be deemed to have accepted, and will be bound by, such changes if you continue to use the Website after the posting of any changes to these Terms.</li>
                </ol>
              </li>

              <li><span className="uppercase">Other terms that may apply to you</span>
                <ol>
                  <li>These Terms refer to the following additional terms, which also apply to your use of our Website:
                    <ol>
                      <li>Our <Link to="/privacy-notice" title="Privacy Notice">Privacy Notice</Link> which explains how your personal information is used by us and what your rights are.</li>
                      <li>Our <Link to="/cookies-policy" title="Cookies Policy">Cookies Policy</Link>, which sets out information about the cookies on our Website.</li>
                      <li>Additional terms and conditions will apply to purchases of goods or services and to specific portions or features of the Website or Brand Websites, including contests, promotions or other similar features, all of which terms are made a part of these Terms by reference. We will direct you to the additional terms and conditions at the time that you interact with us and/or our brands in relation to the relevant services. Please read the applicable terms and conditions carefully. If there is a conflict between these Terms and the terms that apply to a service offered through the Website or other Brand Websites, the terms that apply to that service shall take precedence to the extent of such conflict.</li>
                    </ol>
                    Each of these policies, terms and conditions may be changed from time to time and is effective immediately upon posting such changes on the Website or otherwise in accordance with their terms (as applicable).</li>
                </ol>
              </li>

              <li><span className="uppercase">General</span>
                <ol>
                  <li>If you are a business, these Terms contain the entire agreement between you and us with respect to the use of the Website. No representation, statement or inducement (whether oral or written) not contained in these Terms (as updated from time to time) shall be binding on either you or us.</li>
                  <li>If any provision of these Terms is found by a court or a regulator to be illegal, void, invalid or unenforceable the other provisions shall continue to apply and such provision shall be replaced by another provision which, being valid in all respects, shall have an effect as close as possible to that of the replaced provision.</li>
                  <li>These Terms are not intended to give rights to anyone except you and us (including any of our affiliates). None of these Terms will be enforceable by any third party including (if you are domiciled in the UK) any enforcement through the Contracts (Rights of Third Parties) Act 1999.</li>
                  <li>If we do not insist immediately that you do anything you are required to do under these Terms, or if we delay in taking steps against you in respect of your breaching these Terms, that will not mean that you do not have to do those things and it will not prevent us from taking steps against you at a later date.</li>
                </ol>
              </li>

              <li><span className="uppercase">GOVERNING LAW AND JURISDICTION</span>
                <ol>
                  <li>In the event of any dispute between you and us arising out of or in connection with these Terms, the laws of England will apply.</li>
                  <li>The English courts shall have exclusive jurisdiction to settle any disputes (including non-contractual disputes or claims) that may arise out of or in connection with these Terms.</li>
                </ol>
              </li>

            </ol>

            <p><strong><em>These Terms were last updated on [ ] May 2018</em></strong></p>

          </section>
        </main>
      </Layout>
    )
  }
} 

export default Terms